<template>
    <div class="vue-template forbgl">
        <div class="container ">
            <div class="row mt-5 mb-5">
                <div class="col-md-8 offset-md-2 vertical-center loginw">
                    <h3 class="text-center mb-4">Checkout Payment</h3>

                    <div class="my-3" v-if="upgradeMode">Plan Upgrade</div>
                    <div class="my-3" v-else>New Plan Purchase</div>
                    <hr />

                    <form class="">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label>Name</label>
                                <input
                                    v-model="isLogged.name"
                                    type="text"
                                    id="name"
                                    class="form-control"
                                    readonly
                                />
                                <div class="status" id="name-status"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label>Email</label>
                                <input
                                    v-model="isLogged.email"
                                    type="text"
                                    id="email"
                                    class="form-control"
                                    readonly
                                />
                                <div class="status" id="email-status"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label>Mobile Number</label>
                                <input
                                    v-model="isLogged.mobile"
                                    id="number"
                                    type="text"
                                    class="form-control"
                                    readonly
                                />
                                <div class="status" id="number-status"></div>
                            </div>

                            <template>
                                <div class="text-center w-100 my-2">
                                    Plan Details to Upgrade
                                    <hr />
                                    Billing Annually.
                                    <br />
                                    <small>Monthly Rate: ₹{{ Math.floor(price / 12) }}</small>
                                </div>
                                <div class="form-group col-md-6" ð>
                                    <label>Name</label>
                                    <input
                                        v-model="plan.plans_name"
                                        type="text"
                                        id="plans_name"
                                        class="form-control"
                                        disabled
                                    />
                                </div>

                                <div class="form-group col-md-6">
                                    <label>Price (Rs)</label>
                                    <input
                                        v-model="price"
                                        id="price"
                                        type="number"
                                        class="form-control"
                                        disabled
                                    />
                                </div>
                            </template>
                        </div>
                        <div class="text-center mt-3">
                            <button @click="makePayment" class="primary-button">
                                Proceed To Pay
                            </button>
                        </div>
                    </form>
                    <!--<div class="row mt-4 small"><div class="col"><router-link to="/register">Forgot Password?</router-link></div> <div class="col text-right">New to? <router-link to="/register">Register Now</router-link></div></div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script src="https://checkout.razorpay.com/v1/checkout.js"></script>

<script>
import request from "../apis/request"
import scrollTop from "./utils/scrollTop"
import { mapState, mapActions, mapGetters } from "vuex"

export default {
    name: "payment",
    components: {},
    data() {
        return {
            id: this.$route.params.id,
            plan: "",
            name: "",
            email: "",
            plans_name: "",
            price: "",
            order_id: "",
            mobile: "",
            isLogged: "",
            upgradeMode: false,
            action: "/planregister",
        }
    },

    async mounted() {
        scrollTop()

        const res = await request.get("/planview/" + this.id)
        this.plan = res.data
        this.price = this.plan.price
        console.log(this.plan, this.currentPlan)

        if (this.currentPlan) {
            if (!this.allowUpgrade(this.plan)) {
                console.log("no upgrade allowed")
                return this.$router.push({ name: "pricing" })
            }
            this.price = this.getUpgradePrice(this.plan)
            this.upgradeMode = true
        } else if (!this.plan) {
            return this.$router.push({ name: "pricing" })
        }

        const isLogged = this.user
        // console.log(isLogged);
        if (isLogged) {
            //console.log(isLogged);
            this.isLogged = isLogged
        }

        // if (localStorage.getItem("rzp_device_id")) {
        //     this.id = localStorage.rzp_device_id
        // }
    },
    computed: {
        ...mapState("auth", {
            user: "user",
            authenticated: "authenticated",
        }),
        ...mapState("plan", {
            currentPlan: "currentPlan",
        }),
        ...mapGetters("plan", {
            getUpgradePrice: "getUpgradePrice",
            allowUpgrade: "allowUpgrade",
        }),
    },
    methods: {
        ...mapActions("auth", {
            fetchUser: "fetchUser",
            logoutUser: "logout",
        }),
        //Function to create order ID
        makePayment: function(ev) {
            ev.preventDefault()

            //   if (this.name.length == 0) {
            //     document.getElementById("name-status").innerHTML =
            //       "<span class='warning' style='color: red;'>You need to fill out this form.</span>";
            //   }
            //   if (this.email.length == 0) {
            //     document.getElementById("email-status").innerHTML =
            //       "<span class='warning' style='color: red;'>You need to fill out this form.</span>";
            //   }
            //   if (this.number.length == 0) {
            //     document.getElementById("number-status").innerHTML =
            //       "<span class='warning' style='color: red;'>You need to fill out this form.</span>";
            //   }
            //   if (
            //     !(
            //       this.validateEmail() &&
            //       this.validatenumber() &&
            //       this.name.length > 0
            //     )
            //   )
            //     return;

            this.name = $("#name").val()
            this.email = $("#email").val()
            this.mobile = $("#number").val()
            this.plans_name = $("#plans_name").val()
            this.price = $("#price").val()

            //console.log(plans_name);

            var data = {}
            data["name"] = this.name
            data["email"] = this.email
            data["number"] = this.mobile
            data["amount"] = this.price
            data["plans_name"] = this.plans_name
            data["plan_id"] = this.plan.id

            const that = this

            request
                .post(this.action, data)
                .then(async (res) => {
                    //this.order_id = this.id;
                    //Initiation of Razorpay PG

                    var rzp1 = new Razorpay({
                        key: "rzp_live_oxDeoBrHaTBpbC",

                        amount: data.amount * 100,
                        name: data.name,
                        currency: "INR",
                        description: data.plans_name,
                        //image: "/images/logo.jpg",
                        //   image: "http://103.212.120.205:8080/public/images/logo.jpg",

                        //Uncomment if you are using handler function

                        handler: async () => {
                            await that.handleRazorpayPayment(res, data)
                        },

                        //callback_url: 'http://13.126.183.214/razorpay/checkstatus/?orderid='+this.order_id,

                        prefill: {
                            name: data.name,
                            email: data.email,
                            contact: data.mobile,
                        },

                        notes: {
                            address: "",
                        },

                        theme: {
                            color: "#CE4536",
                        },
                        //order_id: res.data.order_id,
                        //callback_url: "http://domain.com/#/about/" + this.order_id,
                        //redirect: true
                    })
                    rzp1.open()
                })
                .catch((err) => {
                    console.log("ERR", err)
                })
        },
        validateEmail: function() {
            const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/
            let res = regMail.test(this.email)
            let msg
            if (res === false)
                msg = "<span class='warning' style='color: red;'>Email is not valid yet.</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("email-status").innerHTML = msg
            return res
        },

        validatenumber: function() {
            let res = true
            let digitCount = 0
            for (let i = 0; i < this.number.length; i++) {
                if (!(this.number[i] >= "0" && this.number[i] <= "9")) {
                    res = false
                } else {
                    digitCount++
                }
            }
            let msg
            if (res == false)
                msg = "<span class='warning' style='color: red;'>Oops!!! Digits only.</span>"
            else if (digitCount != 10)
                msg = "<span class='warning' style='color: red;'>No!!! Just 10 digits</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("number-status").innerHTML = msg
            return res && digitCount == 10
        },
        async handleRazorpayPayment(response, data) {
            //alert(response.razorpay_payment_id);

            let url = "/plans/purchase"
            if (this.upgradeMode) {
                url = "/plans/upgrade"
            }
            const body = {
                razorpay_payment_id: response.razorpay_payment_id,
                plans_name: data.plans_name,
                plan_id: data.plan_id,
            }
            request
                .post(url, body)
                .then((response) => {
                    // console.log(response);
                    //this.$router.push({ name: "dashboard" });

                    this.$toasted.success("Payment success!", {
                        keepOnHover: true,
                        iconPack: "fontawesome",
                        icon: "check",
                        theme: "toasted-primary",
                        // timerProgressBar: true,
                        duration: 3000,
                    })

                    // alert("Payment successfully!");
                    // location.href = "http://localhost:8080/dashboard";
                    location.href = "/dashboard"
                })
                .catch((error) => {
                    // Wu oh! Something went wrong
                    console.log(error.message)
                })
        },

        //if you are using handler function

        // verify: function(response) {

        // }
    },
}
</script>
